<template>
  <div class="app">
    <the-header></the-header>
    <main-content v-if="!isModalVisible" @open="showModal"></main-content>
    <modal-window v-if="isModalVisible" @close="closeModal"></modal-window>
  </div>
</template>

<script>
import TheHeader from "./components/layouts/TheHeader.vue";
import MainContent from "./components/MainContent.vue";
import ModalWindow from "./components/modal-window/ModalWindow.vue";

export default {
  components: {
    TheHeader,
    MainContent,
    ModalWindow
  },
  data() {
    return {
      isModalVisible: false
    }
  },
  methods: {
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    }
  }
};
</script>

<style>
body,
html {
  padding: 0;
  margin: 0;
  font-family: "Noto Sans", sans-serif;
}

.app {
  position: relative;
  background-image: url("@/assets/background.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  display: flex;
  width: 100%;
}
</style>