<template>
  <draggable
    class="grid-drop-area"
    :list="droppedItems"
    group="draggable-items-group"
    :itemKey="id"
    :animation="200"
    @start="handleDragStart"
    @end="handleDragEnd"
  >
    <!-- Define the item slot for each draggable item -->

    <template v-slot:item="{ element }">
      <form>
        <div
          :class="['form-margin', { 'form-margin-invalid': isEmailInvalid }]"
        >
          <div :class="{ invalid: hasError(element) }">
            <div class="dropped-item-wrapper">
              <input
                v-if="element.name !== 'Message'"
                class="element-input"
                :key="element.id"
                v-model="element.enteredValue"
                :placeholder="element.name"
                :type="element.type"
                :required="element.required"
                @blur="handleBlur(element)"
                @focus="clearErrors(element)"
              />
              <textarea
                v-else-if="element.name === 'Message'"
                class="element-textarea"
                :key="'textarea-' + element.id"
                v-model="element.enteredValue"
                :placeholder="element.name"
                :type="element.type"
                :required="element.required"
                @blur="handleBlur(element)"
                @focus="clearErrors(element)"
              />
              <v-icon
                name="io-close"
                class="input-icon"
                @click="deleteItem(element)"
              ></v-icon>
            </div>
            <p v-if="hasError(element)" class="errorMessage">
              {{ getErrorMessage(element) }}
            </p>
          </div>
        </div>
      </form>
    </template>
  </draggable>
</template>

<script>
import draggable from "vuedraggable";
import { OhVueIcon, addIcons } from "oh-vue-icons";
import {
  IoCloseOutline,
  IoCloseCircleOutline,
  MdEmailOutlined,
  HiUser,
  HiUsers,
  RiMessage2Line,
  IoClose,
} from "oh-vue-icons/icons";

addIcons(
  IoCloseOutline,
  IoCloseCircleOutline,
  MdEmailOutlined,
  HiUser,
  HiUsers,
  RiMessage2Line,
  IoClose
);

export default {
  components: {
    draggable,
    "v-icon": OhVueIcon,
  },
  props: {
    droppedItems: Array,
    userEmailValidity: String,
    recentlyDragged: Boolean,
  },
  methods: {
    handleBlur(element) {
      if (!this.recentlyDragged) {
        this.$emit("blur", element);
      }
    },
    deleteItem(element) {
      this.$emit("delete-item", element);
    },
    clearErrors(element) {
      this.$emit("focus", element);
    },
    handleDragStart() {
      this.$emit("update-dragged", true);
    },
    handleDragEnd() {
      this.$emit("update-dragged", false);
    },
    hasError(element) {
      return element.type === "email" && this.isEmailInvalid;
    },
    getErrorMessage(element) {
      if (element.type === "email") {
        if (this.userEmailValidity === "invalid") {
          return "Invalid email";
        } else if (this.userEmailValidity === "empty") {
          return "Email must be filled";
        }
      }
      return "";
    },
  },
  computed: {
    isEmailInvalid() {
      return (
        this.userEmailValidity === "invalid" ||
        this.userEmailValidity === "empty"
      );
    },
  },
};
</script>

<style scoped>
.grid-drop-area {
  padding: 15px 15px;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 436px;
}

.form-margin {
  margin: 10.25px 0px 10.25px 0px;
}

.form-margin-invalid {
  margin: 7.125px 0px 7.125px 0px !important;
}

.dropped-item-wrapper {
  position: relative;
  display: flex;
}

.element-input {
  position: relative;
  width: 100%;
  border: 1px solid #cccccc;
  border-radius: 8px;
  box-sizing: border-box;
  height: 48px;
  padding: 12px 34px 12px 12px;
  cursor: pointer;
}

.element-textarea {
  position: relative;
  width: 100%;
  border: 1px solid #cccccc;
  border-radius: 8px;
  box-sizing: border-box;
  height: 86px;
  resize: none;
  padding: 12px 34px 12px 12px;
  cursor: pointer;
}

textarea::placeholder {
  text-align: left;
  /* Horizontally center placeholder text */
  line-height: 62px;
}

.input-icon {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 16px;
  width: 16px;
  padding: 10px;
  color: #c3c2c2;
  cursor: pointer;
}

.invalid input {
  border-color: red;
  color: red;
  margin-bottom: 0.5px;
}

.invalid input::placeholder {
  color: red;
}

.errorMessage {
  color: red;
  font-size: 12px;
  margin: 0px;
  padding-left: 12px;
  font-weight: 700;
}

.sortable-ghost {
  visibility: hidden;
}
</style>
